import langDA from '@angular/common/locales/da';

import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { CustomerState } from 'src/modules/customer/state/customer.state';
import { environment } from 'src/environments/environment';
import { DatePipe, registerLocaleData } from '@angular/common';
import { PartnerState } from 'src/modules/partner/state/partner.state';
import { ToastrModule, ToastrService } from 'ngx-toastr';
// import { fancyAnimation } from './route-animations';
import { OverviewState } from 'src/modules/overview/state/overview.state';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CatalogueState } from 'src/modules/catalogue/state/catalogue.state';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedState } from 'src/modules/shared/state/shared.state';
import { FormsModule } from '@angular/forms';
import {
    IonApp,
    IonAvatar,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuButton,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet,
    IonRow,
    IonSkeletonText,
    IonSplitPane,
    IonToolbar,
    provideIonicAngular
} from '@ionic/angular/standalone';

registerLocaleData(langDA, 'da');

@NgModule({
  declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FormsModule,
        ToastrModule.forRoot({positionClass: 'toast-bottom-right', maxOpened: 3}),
        NgxsLoggerPluginModule.forRoot({disabled: environment.production}),
        NgxsModule.forRoot([CustomerState, PartnerState, CatalogueState, OverviewState, SharedState], {
            developmentMode: !environment.production,
        }),

        FontAwesomeModule,
        IonApp,
        IonHeader,
        IonToolbar,
        IonButtons,
        IonMenuButton,
        IonGrid,
        IonRow,
        IonCol,
        IonSplitPane,
        IonMenu,
        IonList,
        IonItem,
        IonAvatar,
        IonSkeletonText,
        IonLabel,
        IonContent,
        IonListHeader,
        IonNote,
        IonMenuToggle,
        IonIcon,
        IonButton,
        IonRouterOutlet,
    ],
  providers: [
    NgxsModule,
    ToastrService,
    DatePipe,
    provideIonicAngular({ mode: 'md' }),
    {
      provide: LOCALE_ID,
      useValue: 'da',
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
